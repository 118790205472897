import React from 'react';
import { Spacing, spacingToTWClass } from './spacing';
import { Body1Bold, Body2Bold } from './Typography';

export interface ButtonProps extends Omit<React.ComponentProps<'button'>, 'className'> {
  variant?: 'primary' | 'danger' | 'text' | 'hollow';
  icon?: React.FC<{ spacing?: Partial<Spacing> }> | undefined;
  spacing?: Partial<Spacing>;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ variant = 'primary', ...buttonProps }, ref) => {
  switch (variant) {
    case 'primary':
      return <PrimaryButton {...buttonProps} ref={ref} />;
    case 'danger':
      return <DangerButton {...buttonProps} ref={ref} />;
    case 'text':
      return <TextButton {...buttonProps} ref={ref} />;
    case 'hollow':
      return <HollowButton {...buttonProps} ref={ref} />;
  }
});
Button.displayName = 'Button';

interface ButtonVariantProps extends Omit<React.ComponentProps<'button'>, 'className'> {
  icon?: React.FC<{ spacing?: Partial<Spacing> }> | undefined;
  spacing?: Partial<Spacing>;
}

export const PrimaryButton = React.forwardRef<HTMLButtonElement, ButtonVariantProps>(
  ({ icon: Icon, children, ...props }, ref) => {
    return (
      <button
        className="flex w-fit items-center justify-center gap-[10px] rounded-md border-[1px] bg-flexpa-green-success-100 px-6 py-3 text-lg font-semibold text-white disabled:cursor-not-allowed disabled:bg-flexpa-green-success-50 disabled:text-white"
        {...props}
        ref={ref}
      >
        {Icon && <Icon spacing={{ r: 'xs' }} />}
        <Body1Bold>{children}</Body1Bold>
      </button>
    );
  },
);
PrimaryButton.displayName = 'PrimaryButton';

export const PrimaryButton2 = React.forwardRef<HTMLButtonElement, ButtonVariantProps>(function PrimaryButton2(
  { icon: Icon, children, ...props },
  ref,
) {
  return (
    <button
      {...props}
      className="flex w-fit items-center justify-center gap-[10px] rounded-md border-[1px] bg-flexpa-green-success-100 px-4 py-3 text-lg font-semibold text-white disabled:cursor-not-allowed disabled:bg-flexpa-green-success-50 disabled:text-white"
      ref={ref}
    >
      {Icon && <Icon />}
      {children}
    </button>
  );
});

export const SecondaryButton = React.forwardRef<HTMLButtonElement, ButtonProps>(({ children, ...props }, ref) => {
  return (
    <button
      className="flex w-fit items-center justify-center gap-[10px] rounded-md border-[1px] border-flexpa-green-success-100 bg-white px-6 py-3 text-lg font-semibold text-flexpa-green-success-100 disabled:cursor-not-allowed disabled:border-flexpa-green-success-75 disabled:bg-flexpa-gray-primary-10 disabled:text-flexpa-green-success-75"
      {...props}
      ref={ref}
    >
      <Body1Bold>{children}</Body1Bold>
    </button>
  );
});
SecondaryButton.displayName = 'SecondaryButton';

export const SecondaryButtonBody2 = React.forwardRef<HTMLButtonElement, ButtonProps>(({ children, ...props }, ref) => {
  return (
    <button
      className="flex w-fit items-center justify-center gap-[10px] rounded-md border-[1px] border-flexpa-green-success-100 bg-white px-4 py-3 text-lg font-semibold text-flexpa-green-success-100 disabled:cursor-not-allowed disabled:border-flexpa-green-success-75 disabled:bg-flexpa-gray-primary-10 disabled:text-flexpa-green-success-75"
      {...props}
      ref={ref}
    >
      <Body2Bold>{children}</Body2Bold>
    </button>
  );
});
SecondaryButtonBody2.displayName = 'SecondaryButtonBody2';

const DangerButton = React.forwardRef<HTMLButtonElement, ButtonVariantProps>(
  ({ icon: Icon, spacing, children, ...props }, ref) => {
    const margin = spacing ? spacingToTWClass(spacing) : '';
    return (
      <button
        className={`flex h-10 items-center rounded bg-danger px-4 py-2 text-lg text-white disabled:cursor-not-allowed disabled:bg-danger-disabled ${margin}`}
        {...props}
        ref={ref}
      >
        {Icon && <Icon spacing={{ r: 'xs' }} />}
        {children}
      </button>
    );
  },
);
DangerButton.displayName = 'DangerButton';

const TextButton = React.forwardRef<HTMLButtonElement, ButtonVariantProps>(
  ({ icon: Icon, spacing, children, ...props }, ref) => {
    const margin = spacing ? spacingToTWClass(spacing) : '';
    return (
      <button
        className={`flex h-10 items-center rounded bg-white px-4 py-2 text-lg text-text hover:bg-gray-100 hover:text-gray-700 disabled:cursor-not-allowed disabled:text-text-disabled ${margin}`}
        {...props}
        ref={ref}
      >
        {Icon && <Icon spacing={{ r: 'xs' }} />}
        {children}
      </button>
    );
  },
);
TextButton.displayName = 'TextButton';

const HollowButton = React.forwardRef<HTMLButtonElement, ButtonVariantProps>(function HollowButton(
  { children, ...props },
  ref,
) {
  return (
    <button
      {...props}
      className={'cursor-pointer px-6 py-3 text-flexpa-green-success-100 disabled:cursor-not-allowed'}
      ref={ref}
    >
      {children}
    </button>
  );
});

export const HollowButton2 = React.forwardRef<HTMLButtonElement, ButtonVariantProps>(function HollowButton2(
  { children, ...props },
  ref,
) {
  return (
    <button
      {...props}
      className={'cursor-pointer px-4 py-3 text-flexpa-green-success-100 disabled:cursor-not-allowed'}
      ref={ref}
    >
      {children}
    </button>
  );
});

export default Button;
