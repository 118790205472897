import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import useSWRImmutable from 'swr/immutable';

import logo from '../../../public/flexpa-logo.svg';
import { fetcher } from '../../lib/fetcher';
import { User, Workspace } from '../../api';

const NavBar: React.FC = () => {
  const { data: user } = useSWRImmutable<User>(['/api/portal/users/me'], fetcher);
  const { data: workspace } = useSWRImmutable<Workspace>(['/api/portal/workspaces'], fetcher);

  const displayName = user?.firstName || user?.lastName ? `${user?.firstName} ${user?.lastName}` : user?.email;
  return (
    <nav className="flex w-full items-center justify-between border-b-[1px] border-b-flexpa-gray-primary-05 bg-secondary px-6 py-2">
      <Link href="/" className="flex items-center gap-3 text-sm font-normal">
        <Image src={logo} width={13.5} height={18} alt="" />
        {workspace && <h1>{workspace.name}</h1>}
      </Link>
      <div className="flex items-center gap-2 text-sm">
        {user && (
          <p>
            Hi, <span className="font-semibold">{displayName}</span>
          </p>
        )}
        {user ? (
          <>
            <div
              aria-hidden="true"
              className="size-6 rounded-full border-[1px] border-flexpa-green-secondary-100 bg-flexpa-green-secondary-50"
            ></div>
            {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
            <a href="/api/auth/logout" className="whitespace-nowrap text-left text-gray-700 hover:underline">
              Logout
            </a>
          </>
        ) : (
          <>
            {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
            <a
              href="/api/auth/login"
              className="cursor-pointer whitespace-nowrap text-left text-gray-700 hover:underline"
            >
              Login
            </a>
          </>
        )}
      </div>
    </nav>
  );
};

export default NavBar;
