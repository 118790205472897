import { useId } from 'react';
import { Spacing } from './spacing';
import { ErrorMessage } from './Typography';
import { classNames } from '../../lib/classNames';

interface TextFormFieldProps {
  inputId?: string;
  label?: string;
  error?: string | undefined;
  spacing?: Partial<Spacing>;
  type?: 'email' | 'text' | 'password' | 'number';
}

const TextFormField: React.FC<
  Omit<React.ComponentProps<'input'>, 'id' | 'className' | 'type'> & TextFormFieldProps
> = ({ inputId, label, error, type = 'text', disabled, ...inputProps }) => {
  let id = useId();

  if (inputId) {
    id = inputId;
  }

  return (
    <div className="flex flex-col gap-[2px] text-sm font-normal">
      {label && (
        <label className={classNames(disabled && 'text-flexpa-gray-primary-40')} htmlFor={id}>
          {label}
        </label>
      )}
      <input
        type={type}
        id={id}
        disabled={disabled}
        className="flex w-full items-start justify-between self-stretch rounded border-[1px] border-flexpa-gray-primary-20 bg-white px-3 py-4 placeholder:text-flexpa-gray-primary-40 disabled:border-flexpa-gray-primary-10 disabled:bg-flexpa-gray-primary-05 disabled:text-flexpa-gray-primary-40"
        {...inputProps}
      ></input>
      <ErrorMessage>{error}</ErrorMessage>
    </div>
  );
};

export default TextFormField;
